import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Auth,
  authState,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  User,
  UserCredential
} from '@angular/fire/auth';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';

import firebase from 'firebase/compat/app';
import {
  BehaviorSubject,
  catchError,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  shareReplay,
  switchMap,
  take
} from 'rxjs';

import { environment } from '@environment';
import { ProfileViewModel } from '@features/models/profile.model';
import { SupportAccessViewModel } from '@features/models/support-access.model';
import { ProfileService } from '@features/services/profile.service';
import { SupportAccessService } from '@features/services/support-access.service';
import { Result } from '@shared/models/result.model';
import { LOCAL_STORAGE_KEYS } from '@shared/utils/constants';
import { FirebaseAuthMethod } from '@shared/utils/enums';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private url = `${environment.apis.linkroom}`;

  private _firebaseUser: User | null = null;
  private _user: ProfileViewModel | null = null;
  private _user$ = new BehaviorSubject<ProfileViewModel | null>(null);
  private _loading$ = new BehaviorSubject<boolean>(true);

  private _ticket = new BehaviorSubject<SupportAccessViewModel | undefined>(undefined);
  public _ticket$ = this._ticket.asObservable();

  get firebaseUser() {
    return this._firebaseUser;
  }

  get ticket() {
    return this._ticket.value;
  }

  get user() {
    return this._user;
  }

  get user$() {
    //  return this._user$.asObservable();
    return this._user$.asObservable().pipe(shareReplay(1));
  }

  get loading$() {
    return this._loading$.asObservable();
  }

  constructor(
    private http: HttpClient,
    private auth: Auth,
    private router: Router,
    private profileService: ProfileService,
    private supportAccessService: SupportAccessService,
    private firestore: Firestore
  ) {
    authState(auth as Auth)
      .pipe(
        switchMap((user: User | null) => {
          this._firebaseUser = user;

          if (!user) {
            this._user = null;
            this._loading$.next(false);
            return [null];
          }

          if (user.isAnonymous) {
            const anonymousProfile: ProfileViewModel = {
              _id: user.uid,
              active: true,
              email: '',
              firstName: 'Guest',
              lastName: new Date().getTime().toString(),
              sa: false,
              needsNavigationTutorial: true
            };

            this._user = anonymousProfile;
            this._user$.next(anonymousProfile);
            this._loading$.next(false);
            return [anonymousProfile];
          }

          return this.profileService.get(user.uid).pipe(
            catchError(() => {
              this._user = null;
              return [null];
            }),
            map(result => result?.data || null)
          );
        })
      )
      .subscribe((profile: ProfileViewModel) => {
        this._user = profile;
        this._user$.next(profile);
        this._loading$.next(false);
      });
  }

  async getTicket() {
    if (this._ticket.value) return this._ticket.value;

    const id = localStorage.getItem(LOCAL_STORAGE_KEYS.TICKET);
    if (!id) return undefined;

    const ticket = await lastValueFrom(this.supportAccessService.get(id));

    if (!ticket.data || ticket.data.end < Date.now()) {
      this._ticket.next(undefined);
      return undefined;
    }

    this._ticket.next(ticket.data);
    return ticket.data;
  }

  async confirmPasswordReset(code: string, password: string) {
    try {
      await confirmPasswordReset(this.auth, code, password);
    } catch (error) {
      throw error;
    }
  }

  resetPassword(email: string) {
    return this.http.post<Result<string>>(`${this.url}/auth/reset-password`, { email });
  }

  async retrieveToken() {
    try {
      const user: User | null = await lastValueFrom(authState(this.auth).pipe(take(1)));
      return user ? await user.getIdToken() : null;
    } catch (error) {
      throw error;
    }
  }

  getEmailAuthProviders(email: string) {
    return this.http.get<Result<any>>(`${this.url}/public/auth/providers/${email}`);
  }

  async signIn(email: string, password: string) {
    try {
      const credential = await signInWithEmailAndPassword(this.auth, email, password);
      this.loadProfile(credential);
    } catch (error) {
      this._user = null;
      this._user$.next(null);
      throw error;
    }
  }

  async loadProfile(credential: UserCredential) {
    const { data } = await lastValueFrom(this.profileService.get(credential.user.uid));

    if (data) {
      this._user = data;
      this._user$.next(data);
    } else {
      const { data } = await lastValueFrom(
        this.profileService.add({
          _id: credential.user.uid,
          email: credential.user.email || '',
          firstName: credential.user.displayName || '',
          lastName: ''
        })
      );

      this._user = data;
      this._user$.next(data);
    }
  }

  async signInWithPopup() {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({ redirect_uri: environment.misc.authDomain });
      const credential = await signInWithPopup(this.auth, provider);
      const { data } = await lastValueFrom(this.profileService.get(credential.user.uid));

      if (data) {
        this._user = data;
        this._user$.next(data);
      } else {
        const { data } = await lastValueFrom(
          this.profileService.add({
            _id: credential.user.uid,
            email: credential.user.email || '',
            firstName: credential.user.displayName || '',
            lastName: ''
          })
        );

        this._user = data;
        this._user$.next(data);
      }
    } catch (error) {
      this._user = null;
      this._user$.next(null);
      throw error;
    }
  }

  async signInSocialNetwork(authMethod: FirebaseAuthMethod, isDesktop: boolean) {
    let provider;

    switch (authMethod) {
      case FirebaseAuthMethod.FACEBOOK:
        provider = new FacebookAuthProvider();
        break;

      case FirebaseAuthMethod.GOOGLE:
        provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        break;
      default:
        throw new Error('Invalid auth method');
    }

    if (provider) {
      provider.setCustomParameters({ redirect_uri: environment.misc.authDomain });
      if (isDesktop) {
        return signInWithRedirect(this.auth, provider);
      } else {
        return signInWithPopup(this.auth, provider);
      }
    } else {
      return undefined;
    }
  }

  getRedirectedResult() {
    return getRedirectResult(this.auth);
  }

  async signOut() {
    try {
      await signOut(this.auth);
      this._user = null;
      this._user$.next(null);
    } catch (error: any) {
      console.error(error.message);
    }
  }

  async signUp(email: string, password: string, firstName: string, lastName: string) {
    try {
      const credential = await createUserWithEmailAndPassword(this.auth, email, password);

      const { data } = await lastValueFrom(
        this.profileService.add({
          _id: credential.user.uid,
          email,
          firstName,
          lastName
        })
      );

      this._user = data;
      this._user$.next(data);
    } catch (error) {
      this._user = null;
      this._user$.next(null);
      throw error;
    }
  }

  async getCurrentUserAsync(): Promise<firebase.User | null> {
    try {
      if (this._firebaseUser) {
        return this._firebaseUser as unknown as firebase.User;
      }

      const user = await firstValueFrom(authState(this.auth));
      return user as unknown as firebase.User | null;
    } catch (error) {
      console.error('Error getting current user:', error);
      return null;
    }
  }

  async signInAnonymously() {
    const auth = getAuth();
    return await signInAnonymously(auth);
  }

  listenClientSessionIdUpdates(profileId: string): Observable<any> {
    const docRef = doc(this.firestore, `sessions/${profileId}`);
    return docData(docRef);
  }

  async updateClientSessionId(profileId: string, clientSessionId: string) {
    const docReferences = doc(this.firestore, 'sessions', profileId);
    return setDoc(docReferences, { clientSessionId });
  }

  getUserPhoto(uid: string) {
    return this.http.get<Result<any>>(`${this.url}/auth/user-photo/${uid}`);
  }

  agoraAuth(user: string, channel: string) {
    return this.http.post<Result<{ token: string }>>(`${this.url}/auth/agora`, { user, channel });
  }
}
